import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Chart } from 'primereact/chart';
import { URL_AXIOS, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import JoditEditor from 'jodit-react';
import { Panel } from 'primereact/panel';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from 'primereact/floatlabel';
import { FaRegFilePdf } from "react-icons/fa";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import Loading from './Loading';
import { Paginator } from 'primereact/paginator';
function Statistiques() {
  const editor = useRef(null);
  const {
    disableAvis, setDisableAvis,
    dataExamenStatistique, setDataExamenStatistique,
    periodeContext, setPeriodeContext,
    membreValueContext, setMembreValueContext,
    examen_total, setExamen_total
  } = useContext(ThemeContext);
  const [modaliteData, setModaliteData] = useState([]);
  let totaleachRowGet = 0;
  let totalJAnvier = {};
  let totalFevrier = {};
  let totalMars = {};
  let totalAvril = {};
  let totalMai = {};
  let totalJuin = {};
  let totalJuillet = {};
  let totalAout = {};
  let totalSeptembre = {};
  let totalOctobre = {};
  let totalNovembre = {};
  let totalDecembre = {};
  let titre_examens = {};
  let totalFact = 0;
  let totalFact2 = 0;
  let tva = 0.18;
  let ca = 0.05;
  let total_prix_uni_value = 0;
  let total_prix_uni_values = {};
  let total_net_a_payer_values = {};
  let total_general_hors_taxe_values = 0;
  let number_total_of_exman = {};
  let total_amount_of_pu = {};
  let number_total_of_exmanAll = 0;
  let total_ca = 0;
  let Janvier_total = 0;
  let Fevrier_total = 0;
  let Mars_total = 0;
  let Avril_total = 0;
  let Mai_total = 0;
  let Juin_total = 0;
  let Juillet_total = 0;
  let Aout_total = 0;
  let septembre_total = 0;
  let Octobre_total = 0;
  let Novembre_total = 0;
  let Decembre_total = 0;
  const usernameconnected = sessionStorage.getItem("nom");
  const user_id = sessionStorage.getItem("user_id");
  const membre_id = sessionStorage.getItem("membre_id");
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [donnee, setDonnee] = useState([]);
  const [periode, setPeriode] = useState("");
  const [sites, setSites] = useState([]);
  const [sitesOri, setSitesOri] = useState([]);
  const [root_stat_site, setRoot_stat_site] = useState([]);
  const [total_for_center, setTotal_for_center] = useState([]);
  const [labels, setLabels] = useState([]);
  const [eachSiteData, setEachSiteData] = useState([]);
  const [eachSiteMontsData, setEachMontsSiteData] = useState([]);
  // const [total_ca_foreach_month,setTotal_ca_foreach_month]=useState([]);
  // const [total_net_foreach_month,setTotal_net_foreach_month]=useState([]);
  const [nbrExamen_total_foreach_months, setNbrExamen_total_foreach_months] = useState([]);
  const [observation, setObservation] = useState("");
  const [janData, setJanData] = useState(0);
  const [fevData, setFevData] = useState(0);
  const [marData, setMarData] = useState(0);
  const [avrData, setAvrData] = useState(0);
  const [maiData, setMaiData] = useState(0);
  const [junData, setJunData] = useState(0);
  const [julData, setJulData] = useState(0);
  const [aouData, setAouData] = useState(0);
  const [sepData, setSepData] = useState(0);
  const [octData, setOctData] = useState(0);
  const [novData, setNovData] = useState(0);
  const [decData, setDecData] = useState(0);
  const [totaleachRow, setTotaleachRow] = useState([]);
  const [totaleachRowByMonth, setTotaleachRowByMonth] = useState([]);
  const [eachSiteDataSubTotal, setEachSiteDataSubTotal] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [membreData, setMembreData] = useState([]);
  const [membreValue, setMembreValue] = useState(0);
  const [societe, setSociete] = useState("");
  const [societeM, setSocieteM] = useState("");
  const [modalite, setModalite] = useState('');
  const [modaliteM, setModaliteM] = useState('');
  const [sexe, setSexe] = useState('');
  const [sexeM, setSexeM] = useState('');
  const [motcle, setMotcle] = useState("");
  const [motcleM, setMotcleM] = useState("");
  const [age, setAge] = useState();
  const [ageF, setAgeF] = useState();
  const [ageM, setAgeM] = useState();
  const [ageFM, setAgeFM] = useState();
  const [boolBtn, setBoolBtn] = useState(true);
  const [statistiqueDataFacturation, setStatistiqueDataFacturation] = useState([]);
  const [facturationNbrExamen, setFacturationNbrExamen] = useState([]);
  const [totalFacturation, setTotalFacturation] = useState(0);
  const [datedebut, setDateDebut] = useState('');
  const [datefin, setDateFin] = useState('');
  const [boolBtnDate, setBoolBtnDate] = useState(true);
  const [site_id, setSite_id] = useState(null);
  const [statistiqueAllDataGroupMembre, setStatistiqueAllDataGroupMembre] = useState([]);
  const [data_FacturationByMembre, setData_FacturationByMembre] = useState([]);
  const [prix_total_sans_pourcent, setPrix_total_sans_pourcent] = useState([]);
  const [net_a_payer, setNet_a_payer] = useState([]);
  const [prix_hors_taxe, setPrix_hors_taxe] = useState([]);
  const [sommeGenerale, setSommeGenerale] = useState(0);
  const [total_pu_sans_percent, setTotal_pu_sans_percent] = useState(0);
  const [total_prix_uni, setTotal_prix_uni] = useState([]);
  const [societeName, setSocieteName] = useState("");
  const [loading, setLoading] = useState(true);
  const [data_cards_by_month, setData_cards_by_month] = useState([]);
  const [data_titre, setData_titre] = useState([]);
  const [nbr_global_of_examens, setNbr_global_of_examens] = useState([]);
  const [loadingSection, setLoadingSection] = useState(true);
  const [loadingFacturationSection, setLoadingFacturationSection] = useState(true);
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingSearchFacturation, setLoadingSearchFacturation] = useState(false);

  //PAGINATION

  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 15; // Nombre d'éléments par page

  // Calculer les données à afficher pour la page actuelle
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = dataExamenStatistique.slice(startIndex, endIndex);

  // Gérer le changement de page
  const onPageChange = (e) => {
    setCurrentPage(e.page);
  };






  const liste_modalite = async () => {
    try {
      const response = await axios.get(URL_AXIOS + 'list_modalite');
      if (response.data.status === 200) {
        setModaliteData(response.data.modalites);
      }
    } catch (Error) {

    }
  }
  const handleInputAge = (event) => {
    const value = event.target.value;
    const regex = /^-?\d*$/;

    if (value !== "") {
      setAge(value)
    } else {
      setAge("")
    }
    if (regex.test(value)) {
      setAge(value)
    } else {
      setAge("")
    }
  };

  const liste_site = async () => {
    try {
      const response = await axios.get(URL_AXIOS + 'sites');
      if (response.data.status === 200) {
        setSitesOri(response.data.sites);
        //setLogin(response.data.sites.length)
        //setFilteredData(response.data.sites);
      }
    } catch (Error) {

    }

  }
  const handleInputAgeF = (event) => {
    const value = event.target.value;
    const regex = /^-?\d*$/;

    if (value !== "") {
      setAgeF(value)
    } else {
      setAgeF("")
    }
    if (regex.test(value)) {
      setAgeF(value)
    } else {
      setAgeF("")
    }
  };
  function formatNumberWithSpaces(number) {
    // Convert the number to a string
    var numberString = number.toString();

    // Split the string into parts separated by three digits
    var parts = [];
    while (numberString.length > 3) {
      parts.unshift(numberString.slice(-3));
      numberString = numberString.slice(0, -3);
    }
    parts.unshift(numberString);
    // Join the parts with a space in between
    return parts.join(' ');
  }

  async function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }

  const generateToPdf = async () => {
    try {
      const fact = "E-METECH_Facture_du " + datedebut + " au " + datefin;
      const doc = new jsPDF();

      // const size_img = document.getElementById("entete_img");
      // const image = await addImageProcess("../../img/ent-em.PNG");

      // // Ajouter l'image au début du document
      // doc.addImage(image, "PNG", 0, 0, 200, 50);

      let spaceAfterImage = 60;
      const text = "ETAT DES PRESTATIONS DES MEDECINS RADIOLOGUES";
      doc.setFont("helvetica", "bold");  // Set font to Helvetica Bold
      // Get the page width
      const pageWidth = doc.internal.pageSize.getWidth();

      // Get the width of the text
      const textWidth2 = doc.getTextWidth(text);

      // Calculate the x position to center the text
      const x = (pageWidth - textWidth2) / 2;

      // Define the y position
      const y = 15;

      // Add the text to the document, centered
      doc.text(text, x, y);

      // Set the line width for the underline
      doc.setLineWidth(0.5);

      // Draw the underline
      doc.line(x, y + 1, x + (textWidth2 + 3), y + 1);

      if ((datedebut !== null && datefin !== null) && (datedebut !== "" && datefin !== "")) {
        const dateStat = new Date(datedebut);
        const dateEnd = new Date(datefin);
        const dayStat = String(dateStat.getDate()).padStart(2, '0');
        const monthStat = String(dateStat.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        const dayEnd = String(dateEnd.getDate()).padStart(2, '0');
        const monthEnd = String(dateEnd.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month

        const yearEnd = String(dateEnd.getFullYear()).slice(-2);
        const fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont(undefined, 'bold');
        const invoiceNumber = `${dayStat}${monthStat}${dayEnd}${monthEnd}${yearEnd}`;
        const centerX = (doc.internal.pageSize.getWidth()) / 2;
        doc.text(`Facture N°: ${invoiceNumber}`, 130, 30)
        doc.text(`Ref.Affaire: Interpretaion des \n images Radiologique`, 15, 30);
        doc.text(`Client: ${societeName}`, 15, 45);
        const currentDate = new Date(datedebut);
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        const year = currentDate.getFullYear();

        const currentDateFin = new Date(datefin);
        const dayFin = String(currentDateFin.getDate()).padStart(2, '0');
        const monthFin = String(currentDateFin.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        const yearFin = currentDateFin.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        const formattedDateFin = `${dayFin}/${monthFin}/${yearFin}`;
        doc.text(`Periode: ${formattedDate + " - " + formattedDateFin}`, 130, 40);
      } else {
        // const dateStat = new Date(datedebut);
        // const dateEnd = new Date(datefin);
        // const dayStat = String(dateStat.getDate()).padStart(2, '0');
        // const monthStat = String(dateStat.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        // const dayEnd = String(dateEnd.getDate()).padStart(2, '0');
        // const monthEnd = String(dateEnd.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month

        // const yearEnd = String(dateEnd.getFullYear()).slice(-2);

        // const currentDate = new Date(datedebut);
        // const day = String(currentDate.getDate()).padStart(2, '0');
        // const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        // const year = currentDate.getFullYear();
        // const invoiceNumber = `${dayStat}${monthStat}${dayEnd}${monthEnd}${yearEnd}`;

        const fontSize = 12;
        doc.setFontSize(fontSize);
        doc.setFont(undefined, 'bold');
        // const invoiceNumber = `${dayStat}${monthStat}${dayEnd}${monthEnd}${yearEnd}`;
        const centerX = (doc.internal.pageSize.getWidth()) / 2;
        doc.text(`Facture N°: *`, 130, 30)
        doc.text(` Ref.Affaire: Interpretation des \n images Radiologique`, 15, 30);
        doc.text(`Client: ${societeName}`, 15, 45);
        // const currentDate = new Date(datedebut);
        // const day = String(currentDate.getDate()).padStart(2, '0');
        // const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        // const year = currentDate.getFullYear();

        // const currentDateFin = new Date(datefin);
        // const dayFin = String(currentDateFin.getDate()).padStart(2, '0');
        // const monthFin = String(currentDateFin.getMonth() + 1).padStart(2, '0'); // Note: getMonth() returns zero-based month
        // const yearFin = currentDateFin.getFullYear();

        // const formattedDate = `${day}/${month}/${year}`;
        // const formattedDateFin = `${dayFin}/${monthFin}/${yearFin}`;
        doc.text(`Periode:*`, 130, 40);
      }

      // Parcourir les données de statistiques par membre
      statistiqueAllDataGroupMembre.forEach((membreData) => {
        const { membre_id } = membreData;

        // Générer le tableau pour les données de facturation du membre
        autoTable(doc, {
          html: '#table_facture_' + membre_id,
          startY: spaceAfterImage,
          columnStyles: {
            1: { halign: 'center' }, // Centre le texte de la colonne 1
            2: { halign: 'right', cellWidth: 30, },   // Aligner le texte de la colonne 2 à gauche
            3: { halign: 'right', cellWidth: 30 },
            4: { halign: 'center', halign: 'center' },
            5: { halign: 'right', cellWidth: 30 }  // Aligner le texte de la colonne 3 à droite
          },
          didParseCell: function (data) {
            if (data.section === 'head') {
              // Add styles for header cells
              // data.cell.styles.halign = 'center';
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.textColor = "#000";
              data.cell.styles.fillColor = '#dddddd'; // Example background color
              data.cell.styles.textAlign = "center";

              if (data.row.index === 0) {
                data.cell.styles.fillColor = [9, 110, 181]; // Changer la couleur de fond en jaune
                data.cell.styles.textColor = [252, 252, 252];

              }
              if (data.row.index === 1) {
                data.cell.styles.fillColor = [181, 26, 77]; // Changer la couleur de fond en jaune
                data.cell.styles.textColor = [252, 252, 252];
                data.cell.styles.textAlign = "center";
              }
              // if (data.column.index === 1) { // Colonne 1 dans le pied de page
              //   data.cell.styles.halign = 'center'; // Centre le texte

              // }
              // if (data.column.index === 2) { // Colonne 1 dans le pied de page
              //   data.cell.styles.halign = 'center'; // Centre le texte
              // }
              // if (data.column.index === 3) { // Colonne 1 dans le pied de page
              //   data.cell.styles.halign = 'center'; // Centre le texte
              // }
              // if (data.column.index === 4) { // Colonne 1 dans le pied de page
              //   data.cell.styles.halign = 'center'; // Centre le texte
              // }
              // if (data.column.index === 5) { // Colonne 1 dans le pied de page
              //   data.cell.styles.halign = 'center'; // Centre le texte
              // }
            } else if (data.section === 'body') {
              // Add styles for body cells
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.textColor = "#000";
              //data.cell.styles.fillColor = '#0000'; // Example background color

              if (data.column.index === 1) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 2) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }
              if (data.column.index === 3) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }
              if (data.column.index === 4) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 5) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }
            } else if (data.section === 'foot') {
              // Add styles for body cells
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.textColor = "#000";
              data.cell.styles.fillColor = '#827f7f'; // Example background color

              if (data.column.index === 1) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 2) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }
              if (data.column.index === 3) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }
              if (data.column.index === 4) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 5) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
                // data.cell.styles.width = '15%';
              }

              if (data.row.index === 1) {
                data.cell.styles.fillColor = [209, 205, 206]; // Changer la couleur de fond en jaune
                data.cell.styles.textColor = [0, 0, 0];
              }
            } else if (data.section === 'foot') {
              // Add styles for body cells
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.textColor = "#000";
              data.cell.styles.fillColor = '#827f7f'; // Example background color

              if (data.column.index === 1) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 2) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
              }
              if (data.column.index === 3) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
              }
              if (data.column.index === 4) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'center'; // Centre le texte
              }
              if (data.column.index === 5) { // Colonne 1 dans le pied de page
                data.cell.styles.halign = 'right'; // Centre le texte
              }

              if (data.row.index === 1) {
                data.cell.styles.fillColor = [209, 205, 206]; // Changer la couleur de fond en jaune
                data.cell.styles.textColor = [0, 0, 0];
              }
            }
          }
        });

        // Ajouter de l'espace après chaque tableau
        spaceAfterImage = doc.autoTable.previous.finalY + 10;
      });

      autoTable(doc, {
        html: '#table_facture2',
        startY: spaceAfterImage,
        didParseCell: function (data) {
          if (data.section === 'head') {
            // Add styles for header cells
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = [0, 0, 0];
          } else if (data.section === 'foot') {
            // Add styles for body cells
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = [0, 0, 0];
            data.cell.styles.fillColor = [209, 205, 206];

            if (data.column.index === 1) {
              data.cell.styles.halign = 'right';
            }
          }
        },
        didDrawPage: function (data) {
          // Calculate the bottom margin of the table

          spaceAfterImage = doc.autoTable.previous.finalY + 15;
          const bottomMarginOfTable = data.cursor.y + 10; // Add some padding

          // Calculate the space needed for the signature
          const spaceNeededForSignature = 60; // Adjust as needed





          // Check if there's not enough space for the signature
          if (bottomMarginOfTable + spaceNeededForSignature >= doc.internal.pageSize.getHeight()) {
            // Add a new page
            doc.addPage();

          } else {

            // Reset startY for the new page
            spaceAfterImage = 10;

            // // Redraw the table on the new page
            // autoTable(doc, {
            //   html: '#table_facture3',
            //   startY: spaceAfterImage,
            //   columnStyles: {
            //     1: { halign: 'right' }, // Centre le texte de la colonne 1

            //   },
            //   didParseCell: function (data) {
            //     if (data.section === 'head') {
            //       // Add styles for header cells
            //       data.cell.styles.fontStyle = 'bold';
            //       data.cell.styles.textColor = [0, 0, 0];
            //       //data.cell.styles.fillColor = '#fff';
            //       data.cell.styles.fillColor = '#fff'; // Example background color
            //     } else {
            //       // Add styles for body cells
            //       data.cell.styles.fontStyle = 'bold';
            //       data.cell.styles.textColor = [0, 0, 0];
            //       data.cell.styles.fillColor = '#fff'; // Example background color
            //     }
            //   },
            //   margin: { left: 150 }, // Set the left margin
            //   styles: { fontSize: 14 }, // Increase the font size
            //   cellPadding: 5
            // });
            const signatureText = "La direction"; // Adjust as needed
            // doc.text(signatureText, 140, doc.internal.pageSize.getHeight() - 100);
            const marginBottom = 30; // margin in pixels
            const pageHeight = doc.internal.pageSize.getHeight(); // get the height of the page
            const yPosition = pageHeight - marginBottom; // calculate the Y position with the margin

            doc.text(signatureText, 140, yPosition);
          }
        }
      });
      //const signatureText = "La direction"; // Adjust as needed
      //doc.text(signatureText, 140, doc.internal.pageSize.getHeight() - 100);
      // Ajouter un autre tableau s'il y a lieu
      /*autoTable(doc, {
        html: '#table_facture2',
        startY: spaceAfterImage,
        didParseCell: function (data) {
          if (data.section === 'head') {
            // Add styles for header cells
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = [0, 0, 0];
            //data.cell.styles.fillColor = '#dddddd'; // Example background color
          } else {
            // Add styles for body cells
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "#fff";
            //data.cell.styles.fillColor = '#0000'; // Example background color
          }
        },
        // Event triggered when the content overflows to a new page
        didOverflowPage: function (data) {
          // Calculate the bottom margin of the table
          const bottomMarginOfTable = data.cursor.y + 10; // Add some padding

          // Calculate the space needed for the signature
          const spaceNeededForSignature = 50; // Adjust as needed

          // Check if there's not enough space for the signature
          if (bottomMarginOfTable + spaceNeededForSignature >= doc.internal.pageSize.getHeight()) {
            // Add a new page
            doc.addPage();
            // Reset startY for the new page
            spaceAfterImage = 10;
          }else{
            doc.addPage();
            // Reset startY for the new page
            spaceAfterImage = 10;
          }

        }
      });*/
      // // doc.text(`La direction rrrtrt`, 140, 280);
      // const contentAboveSignature = "";
      // const { width: textWidth, height: textHeight } = doc.getTextDimensions(contentAboveSignature);
      // const signatureX = 10; // Adjust as needed
      // const signatureY = textHeight + 10; // Add some padding below the text
      // doc.text(contentAboveSignature, 10, 10);
      // Add the signature text

      // Define a function to add page numbers
      const addPageNumbers = () => {
        const totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          // Go to the current page
          doc.setPage(i);
          // Add the page number
          doc.text(`Page ${i} of ${totalPages}`, 15, doc.internal.pageSize.getHeight() - 10);
        }
      };

      /*const addPageNumber = () => {
        const pageNumber = doc.internal.getNumberOfPages();
        doc.text(`Page ${pageNumber}`, 15, 290);
      };
      doc.autoTable({ startY: false, didDrawPage: addPageNumber });*/
      // Add page numbers after all content and tables are added
      addPageNumbers();
      doc.save(fact);
    } catch (error) {
      console.error("Une erreur s'est produite lors de la génération du PDF :", error);
    }
  };




  const LoadAllSiteByMemberAndSite = async (id, p) => {
    try {
      const response = await axios.get(URL_AXIOS + `allSiteByMemberBySite/${id}/${p}`);
      if (response.data.status === 200) {
        //setSites(response.data.all_site);
        /*setEachSiteData(response.data.examenBySite);
        setEachSiteDataSubTotal(response.data.marsArray);
        setEachMontsSiteData(response.data.data_stat_months);
        setTotaleachRow(response.data.data_stat_total);
        setDataExamenStatistique(response.data.statistiqueAllData);
        setTotaleachRowByMonth(response.data.data_stat_total_for_each_month);
        */
        setStatistiqueDataFacturation(response.data.statistiqueDataFacturation);
        setFacturationNbrExamen(response.data.totalForEachCategorieFacturation);
        setStatistiqueAllDataGroupMembre(response.data.statistiqueAllDataGroupMembre);
        setData_FacturationByMembre(response.data.totalForEachCategorieFacturationByMembre);
        setPrix_total_sans_pourcent(response.data.prix_total_sans_pourcent);
        setNet_a_payer(response.data.net_a_payer);
        setPrix_hors_taxe(response.data.prix_hors_taxe);
        setSommeGenerale(response.data.sommeGeneral);

      } else {

      }
    } catch (error) {

    }
  }
  const cancelRecherche = () => {
    try {
      setBoolBtnDate(true);
      setDateDebut('');
      setDateFin('');
      LoadAllSiteByMemberAndSite(site_id, periode);
    } catch (error) {

    }
  }
  const cancelReseach = () => {
    setBoolBtn(true)
    setSociete('');
    setSocieteM('');
    setAge('');
    setAgeF('');
    setAgeFM('');
    setAgeM('');
    setModalite('');
    setModaliteM('');
    setSexe('');
    setSexeM('');
    setMotcle('');
    setMotcleM('');
    load_data_exams_stat(membreValue, periode);

  }
  const load_data_exams_stat = async (membre, periode) => {
    try {

      const response = await axios.get(URL_AXIOS + `load_data_exams/${membre}/${periode}`);
      if (response.data.status == 200) {
        setDataExamenStatistique(response.data.statistiqueAllData);
        setExamen_total(response.data.sommeTotalForeAllSite)

      } else {

      }


    } catch (error) {

    }
  }
  const rechercher_advanced = async (id, p) => {
    try {

      // const data = {
      //   societe: societe != "" ? societe : "NA",
      //   sexe: sexe != "" ? sexe : "NA",
      //   categorieExamen: modalite != "" ? modalite : "NA",
      //   age: age != "" ? age : "NA",
      //   observation: motcle != "" ? motcle : "NA",
      //   age2: ageF != "" ? ageF : "NA",
      // }
      // setAgeFM(ageF);
      // setAgeM(age);
      // setModaliteM(modalite);
      // setSocieteM(societe);
      // setSexeM(sexe);
      // setMotcleM(motcle);
      // const response = await axios.get(URL_AXIOS + `recherche_in_vue_autres_statistiqueAdvanced/${id}/${p}/${data.societe}/${data.sexe}/${data.age}/${data.categorieExamen}/${data.observation}/${data.age2}`);

      const data = {
        societe: societe,
        sexe: sexe,
        categorieExamen: modalite,
        age: age,
        observation: motcle,
        age2: ageF,
      }

      setAgeFM(data.age2);
      setAgeM(data.age);
      setModaliteM(data.modalite);
      setSocieteM(data.societe);
      setSexeM(data.sexe);
      setMotcleM(data.motcle);
      const response = await axios.post(URL_AXIOS + `recherche_in_vue_autres_statistiqueAdvanced/${id}/${p}`, data);

      if (response.data.status === 200) {

        setDataExamenStatistique(response.data.statistiqueAllData);
        setExamen_total(response.data.sommeTotalForeAllSite)
        setBoolBtn(false)

      } else {
        load_data_exams_stat(membreValue, periode);
      }
    } catch (error) {

    }
  }
  const liste_membres = async () => {
    try {
      const response = await axios.get(URL_AXIOS + 'membres');
      if (response.data.status === 200) {
        setMembreData(response.data.membres);
        //setMembre(response.data.membres.length);
      }
    } catch (Error) {

    }

  }
  const years = [
    {
      label: "2023",
      value: "2023"
    },
    {
      label: "2024",
      value: "2024"
    },
    {
      label: "2025",
      value: "2025"
    },
    {
      label: "2026",
      value: "2026"
    },
    {
      label: "2027",
      value: "2027"
    },
    {
      label: "2028",
      value: "2028"
    },
    {
      label: "2029",
      value: "2029"
    },
    {
      label: "2030",
      value: "2030"
    }
  ];

  const MonthDataCompar = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"];
  const op = useRef(null);



  const Facturation = async (e) => {

    try {
      const value = e.target.value;

      if (value) {
        setSite_id(value);
        const response = await axios.get(URL_AXIOS + `allSiteByMemberBySite/${value}/${periode}`);
        if (response.data.status === 200) {

          // setSocieteName(response.data?.societeData?.societe);
          setStatistiqueDataFacturation(response.data?.statistiqueDataFacturation);
          setFacturationNbrExamen(response.data?.totalForEachCategorieFacturation);
          setStatistiqueAllDataGroupMembre(response.data?.statistiqueAllDataGroupMembre);
          setData_FacturationByMembre(response.data?.totalForEachCategorieFacturationByMembre);
          setPrix_total_sans_pourcent(response.data?.prix_total_sans_pourcent);
          setNet_a_payer(response.data?.net_a_payer);
          setPrix_hors_taxe(response.data?.prix_hors_taxe);
          setSommeGenerale(response.data?.sommeGeneral);
          setTotal_prix_uni(response.data?.total_prix_uni);
          setLoadingFacturationSection(false);

        } else {
          setLoadingFacturationSection(false);
        }
      } else {
        setSite_id(0);

        const response = await axios.get(URL_AXIOS + `allSiteByMemberBySite/${0}/${periode}`);
        if (response.data.status === 200) {
          setSocieteName(response.data?.societeData?.societe);
          setStatistiqueDataFacturation(response.data?.statistiqueDataFacturation);
          setFacturationNbrExamen(response.data?.totalForEachCategorieFacturation);
          setStatistiqueAllDataGroupMembre(response.data?.statistiqueAllDataGroupMembre);
          setData_FacturationByMembre(response.data?.totalForEachCategorieFacturationByMembre);
          setPrix_total_sans_pourcent(response.data?.prix_total_sans_pourcent);
          setNet_a_payer(response.data?.net_a_payer);
          setPrix_hors_taxe(response.data?.prix_hors_taxe);
          setSommeGenerale(response.data?.sommeGeneral);
          setTotal_prix_uni(response.data?.total_prix_uni);
          setLoadingFacturationSection(false);

        } else {
          setLoadingFacturationSection(false);
        }
      }

    } catch (error) {

      setStatistiqueDataFacturation([]);
      setFacturationNbrExamen([]);
      // setStatistiqueAllDataGroupMembre([]);
      setData_FacturationByMembre([]);
      setPrix_total_sans_pourcent([]);
      setLoadingFacturationSection(false);

    }
  }



  const LoadAllSiteByMember = async (id, p) => {
    try {
      const response = await axios.get(URL_AXIOS + `allSiteByMemeber/${id}/${p}`);
      if (response.data.status === 200) {
        setSites(response.data.all_site);
        setEachSiteData(response.data.examenBySite);
        setEachSiteDataSubTotal(response.data.marsArray);
        setEachMontsSiteData(response.data.data_stat_months);
        setTotaleachRow(response.data.data_stat_total);
        setDataExamenStatistique(response.data.statistiqueAllData);
        setTotaleachRowByMonth(response.data.data_stat_total_for_each_month);
        setNbrExamen_total_foreach_months(response.data.nbrExamen_total_foreach_months);
        setData_titre(response.data.data_titre);
        setNbr_global_of_examens(response.data.nbr_global_of_examens);
        console.log(response.data.nbr_global_of_examens)
        setLoadingSection(false)
        /*setStatistiqueDataFacturation(response.data.statistiqueDataFacturation);
        setFacturationNbrExamen(response.data.totalForEachCategorieFacturation);
        setStatistiqueAllDataGroupMembre(response.data.statistiqueAllDataGroupMembre);
        setData_FacturationByMembre(response.data.totalForEachCategorieFacturationByMembre);
        setPrix_total_sans_pourcent(response.data.prix_total_sans_pourcent);
        setNet_a_payer(response.data.net_a_payer);
        setPrix_hors_taxe(response.data.prix_hors_taxe);
        setSommeGenerale(response.data.sommeGeneral);
        */



      } else {
        setLoadingSection(false)
      }
    } catch (error) {
      setLoadingSection(false)
    }
  }

  const rechercherByIntervalDateFacture = async () => {
    try {
      setLoadingSearchFacturation(true);
      const response = await axios.post(URL_AXIOS + `rechercheFactureByDate/${datedebut}/${datefin}/${site_id}/${periode}`);
      if (response.data.status == 200) {
        setStatistiqueDataFacturation(response.data.statistiqueDataFacturation);
        setFacturationNbrExamen(response.data.totalForEachCategorieFacturation);
        setStatistiqueAllDataGroupMembre(response.data.statistiqueAllDataGroupMembre);
        setData_FacturationByMembre(response.data.totalForEachCategorieFacturationByMembre);
        setPrix_total_sans_pourcent(response.data.prix_total_sans_pourcent);
        setNet_a_payer(response.data.net_a_payer);
        setPrix_hors_taxe(response.data.prix_hors_taxe);
        setSommeGenerale(response.data.sommeGeneral);
        setBoolBtnDate(false);
        setLoadingSearchFacturation(false);
      } else {
        setLoadingSearchFacturation(false);
      }

    } catch (error) {
      setLoadingSearchFacturation(false);
    }

  }

  const onChangePeriode = async (e) => {

    try {
      const value = e.target.value;

      const data = {
        membre_id: value,
        periode: periode
      }
      if (periode !== "" && value !== 0) {
        setMembreValueContext(value)
        setMembreValue(value);
        setPeriodeContext(periode);
        const response = await axios.post(URL_AXIOS + "create_statistique", data);
        if (response.data.status === 200) {
          setRoot_stat_site(response.data?.root_stat_site);
          setExamen_total(response.data.sommeTotalForeAllSite)
          setTotal_for_center(response.data.total_for_center);
          setLoadingChart(false)


          setLabels(["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"]);
          let sommeDataJan = response.data.jan.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataFev = response.data.fev.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataMar = response.data.mar.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataAvril = response.data.avr.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataMai = response.data.mai.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataJuin = response.data.jun.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataJul = response.data.jul.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataAou = response.data.aou.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataSep = response.data.sep.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataOct = response.data.oct.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataNov = response.data.nov.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataDec = response.data.dec.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);

          const DataMonthOfYear = [
            sommeDataJan,
            sommeDataFev,
            sommeDataMar,
            sommeDataAvril,
            sommeDataMai,
            sommeDataJuin,
            sommeDataJul,
            sommeDataAou,
            sommeDataSep,
            sommeDataOct,
            sommeDataNov,
            sommeDataDec
          ]
          console.log("Données du mois pour le graphique :", DataMonthOfYear);
          let sommeData = response.data.annee.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);

          if (sommeData > 0) {
            setDonnee(DataMonthOfYear)
          } else {
            setDonnee([])
          }

          // if (MonthOfYearLabels.length > 0) {
          //   setLabels(MonthOfYearLabels)
          // } else {

          //   setLabels(["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"])
          // }

          // const DataMonthOfYear = [
          //   (response.data.jan) ? response.data.jan.netapayer : 0,
          //   (response.data.fev) ? response.data.fev.netapayer : 0,
          //   (response.data.mar) ? response.data.mar.netapayer : 0,
          //   (response.data.avr) ? response.data.avr.netapayer : 0,
          //   (response.data.mai) ? response.data.mai.netapayer : 0,
          //   (response.data.jun) ? response.data.jun.netapayer : 0,
          //   (response.data.jul) ? response.data.jul.netapayer : 0,
          //   (response.data.aou) ? response.data.aou.netapayer : 0,
          //   (response.data.sep) ? response.data.sep.netapayer : 0,
          //   (response.data.oct) ? response.data.oct.netapayer : 0,
          //   (response.data.nov) ? response.data.nov.netapayer : 0,
          //   (response.data.dec) ? response.data.dec.netapayer : 0
          // ]
          // let sommeData = DataMonthOfYear.reduce((acc, valeur) => acc + valeur, 0);

          // if (sommeData > 0) {
          //   setDonnee(DataMonthOfYear)
          // } else {
          //   setDonnee([])
          // }

        } else {
          setLoadingChart(false)

        }
      } else {
        setPeriodeContext("");


      }
    } catch (error) {
      setLoadingChart(false)
    }

  }

  const handleDownload = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.download = 'Example-PDF-File';

    link.href = value;

    link.click();
  };

  const handleOpen = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    //link.download = 'Example-PDF-File';

    link.href = value;
    link.target = "_blank";

    link.click();
  };
  const chartLine = (periode) => {
    try {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      const data = {
        labels: labels,
        datasets: [
          {
            type: 'line',
            label: periode,
            color: "white",
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: "#fff",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            data: donnee
          }

        ]
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.9,
        plugins: {
          legend: {
            labels: {
              color: "#fff",
              font: {
                weight: 'bold'
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          },
          y: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          }
        }
      };

      setChartData(data);
      setChartOptions(options);
    } catch (error) {

    }
  }

  const headerAccordionData = (societe, number) => {
    return (
      <div style={{ display: 'flex', width: "100%" }}>
        <p>{societe}</p>
        <p style={{ marginLeft: "15px", width: "20px", height: "20px", display: 'none', backgroundColor: 'red', borderRadius: "100%", padding: "5px", color: 'white' }}>{number}</p>
      </div>
    )
  }
  const config = {
    style: {
      width: "100%",
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
    height: 'auto',
    allowResizeX: false,
    allowResizeY: false,
    autofocus: false,
    toolbarAdaptive: false,
    editorClassName: 'editorComponent_apercu2',
    readonly: true,
    toolbar: false,
    //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: true,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    statusbar: false,
    // width: '100%',
    zIndex: 0,
    toolbarSticky: false,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'fr',
    tabIndex: -1,
    enter: "P",
    useSplitMode: false,
    colors: {
      greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
      palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
      full: [
        '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
        '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
        '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
        '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
        '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
        '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
      ]
    },
    colorPickerDefaultTab: 'background',
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    buttons: [

    ],
    events: {},
    textIcons: false,
    plugins: ['autoresize'],
  }
  useEffect(() => {
    liste_modalite();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  useEffect(() => {
    if (periode !== "" && membreValue !== 0) {
      LoadAllSiteByMember(membreValue, periode);
    }
  }, [periode, membreValue])
  useEffect(() => {
    liste_site();
    liste_membres();
    chartLine(periode);
    if (usernameconnected === null || usernameconnected === "") {
      navigate('/')
    }
  }, [donnee, periode]);
  useEffect(() => {
    setDisableAvis(true)

  }, [membreValue])

  const headerMainFirst = () => {
    return (
      <div>
        <h5 style={{ color: "red", }}>Nombre des examens interpétés par centre</h5>
      </div>
    )
  }
  const headerMainSecond = (numberExamens) => {
    return (
      <div>
        <h5 style={{ color: 'red', textTransform: 'uppercase' }}>Résultat ({numberExamens}) CR {societeM && ("- " + societeM)} {modaliteM && ("- " + modaliteM)} {sexeM && ("- de sexe " + (sexeM == "M" ? "masculin" : 'féminin'))} {ageF && ("agés de " + ageM + " à " + ageFM + " - ")}   {motcleM && ("- mot cle ") + "(" + motcleM + ")"}   </h5>
      </div>
    )
  }

  const headerPatient = (Patient, Examen, Centre, Fichier, Fichier_2, Bulletin, date, age, sexe) => {
    return (
      <div className='row' style={{ marginLeft: 1, marginTop: "-10px", position: 'absolute', width: "100%" }}>
        <table className='table'>
          <tr style={{ color: 'black' }}>
            <th style={{ width: "28%" }}>{Patient}</th>
            <th style={{ width: "26%" }}>{Centre}</th>
            <th style={{ width: "8%" }}>{age}</th>
            <th style={{ width: "5%" }}>{sexe}</th>
            <th style={{ width: "22%" }}>{Examen}</th>
            <th> {format(date, 'dd/MM/yyyy')}</th>
          </tr>
        </table>


      </div>

    )
  }
  return (
    <div style={{ overflowX: 'none', overflowY: 'none', maxHeight: '730px' }}>
      <Header />
      <AsideBar username={usernameconnected} />
      {loading ? <Loading /> : <section id="main-content">
        <section class="wrapper">
          <div className='row'>
            <div className='card' style={{ width: "100%", height: "12vh" }}>
              <div className='row'>
                <div className='col col-md-1'>

                </div>
                <div className='col col-md-10'>
                  <div style={{ display: "flex", width: '100%' }}>
                    <label style={{ marginTop: 30, fontWeight: 600, color: 'black' }}>Période *:</label>
                    <select className='form-control'
                      onChange={(e) => setPeriode(e.target.value)} value={periode}
                      style={{ marginTop: 20, width: "40%", marginLeft: 5, fontWeight: 600, color: 'black' }}>
                      <option value="">Veuillez sélectionner la période</option>
                      {years?.map((y, index) => (
                        <option key={index} value={y.value}>{y.label}</option>
                      ))}
                    </select>

                    <label style={{ marginTop: 30, fontWeight: 600, color: 'black', marginLeft: 10 }}>Médecin *:</label>
                    <select className='form-control'
                      onChange={(e) => onChangePeriode(e)}
                      style={{ marginTop: 20, width: "40%", marginLeft: 10, fontWeight: 600, color: 'black' }}>
                      <option value="0">Veuillez sélectionner le médecin</option>
                      {membreData.map((m, index) => (
                        <option key={index} value={m.id}>{m.nom + (m.prenom ? " " + m.prenom : "")}</option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className='col col-md-1'>


                </div>
              </div>

            </div>

          </div>
          <div className='row'>
            <div className="card" style={{ height: "auto", width: "98%", marginLeft: "1%", backgroundColor: "#163356" }}>
              {loadingChart && periode && membreValue && loadingSection ? <div style={{ display: "flex", justifyContent: 'center', position: "absolute", marginLeft: "40%", marginTop: "12%" }}>
                {/* <p className='text-center spinner' style={{marginTop:"10%",color:"white",width:"50px",height:"50px",borderRadius:"100%",border:"2px solid red"}}></p> */
                  <div class="spinner"></div>

                }
              </div> : ""}
              <Chart type="line" data={chartData} options={chartOptions} />

            </div>

          </div>
          <div className='row'>

            {/* {loadingSection && periode && membreValue ? <div class="spinner"></div>
              : ""} */}
            {<div className="content" style={{ marginTop: "1%", width: "98%", marginLeft: "1%" }}>
              <form id="form{{ 0 }}" action="{{route('members.statistique')}}" method="GET">
                <div class="row">

                  {
                    sites.map((site, index) => (


                      <div class="col-md-6" key={index}>
                        <div className="card card-chart" style={{ height: "auto" }}>
                          <div class="card-header" style={{ padding: 10 }}>
                            <h5 class="card-category">{site.type}</h5>
                            <p class="card-title" style={{ color: 'red', fontWeight: 'bold' }}>{site.societe}</p>
                            <div class="dropdown">
                              {/* <button type="button" class="btn btn-round btn-outline-default dropdown-toggle btn-simple btn-icon no-caret"
                                style={{ float: 'right' }}
                                onClick={(e) => op.current.toggle(e)}>
                                <i class="fa fa-gear"></i>
                              </button> */}
                              <OverlayPanel ref={op} showCloseIcon closeOnEscape dismissable={false}>
                                <div className='container-fluid'>
                                  <div className='row'>
                                    <button type='button' className='btn btn-primary form-control'>Charger les données</button>
                                  </div>
                                  <br />
                                  <div className='row'>
                                    <button type="button" className="btn btn-info form-control">Liste des prix</button>
                                    <input type="hidden" name="_{{$site->site_id}}" value="{{$site->site_id}}" />
                                  </div>


                                </div>
                              </OverlayPanel>

                            </div>
                          </div>
                          <div class="card-body" style={{ padding: 10 }}>
                            <div class="table-responsive-md">
                              <table class="table">
                                <thead class=" text-primary">
                                  <th>
                                    Période
                                  </th>
                                  <th>
                                    Exam.
                                  </th>
                                  <th>
                                    C.A.
                                  </th>
                                  <th>
                                    %
                                  </th>
                                  <th>
                                    Net
                                  </th>
                                  <th class="text-right"></th>
                                </thead>
                                <tbody>
                                  {

                                    root_stat_site[site?.site_id]?.map((ss, index) => (
                                      <tr key={index}>
                                        <td>
                                          {ss.periode}
                                        </td>
                                        <td>
                                          {ss.nbrExamen}
                                        </td>
                                        <td>

                                          {ss.gain !== 0 ? new Intl.NumberFormat().format(ss.gain) + "  XAF" : 0}

                                        </td>
                                        <td>
                                          {ss.pourcentage}
                                        </td>
                                        <td>
                                          { /* {{number_format($ss->netapayer,0,',',' ')}}{{__(' XAF')}}*/}
                                          {ss.netapayer !== 0 ? new Intl.NumberFormat().format(ss.netapayer) + "  XAF" : 0}

                                        </td>
                                        {/* <td class="text-right">
                                          <i class="fa fa-gear" style={{ fontSize: 20, border: "1px solid grey", padding: 10, borderRadius: "100%", cursor: "pointer" }}></i>
                                        </td> */}
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div class="col-md-12">
                    <div class="card  card-tasks" style={{ width: '100%', height: 'auto' }}>
                      <div class="card-header " style={{ padding: 10 }}>
                        <h3 class="card-category" style={{ textAlign: 'center' }}>Autres statistiques</h3>

                      </div>
                      <div class="card-body " style={{ padding: 10 }}>
                        <Accordion activeIndex={1}>
                          <AccordionTab header={headerMainFirst}>
                            {

                              sites?.map((site, index) => (
                                <Accordion >
                                  <AccordionTab header={headerAccordionData(site.societe, eachSiteData[site.site_id]?.length)}>

                                    <table className='table table-bordered table-striped table-condensed' key={index}>
                                      <thead>
                                        <tr style={{ backgroundColor: 'grey', color: 'white', }}>

                                          <th>TITRE</th>
                                          <th style={{ textAlign: 'center' }}>Jan</th>
                                          <th style={{ textAlign: 'center' }}>Fevr</th>
                                          <th style={{ textAlign: 'center' }}>Mars</th>
                                          <th style={{ textAlign: 'center' }}>Avr</th>
                                          <th style={{ textAlign: 'center' }}>Mai</th>
                                          <th style={{ textAlign: 'center' }}>Juin</th>
                                          <th style={{ textAlign: 'center' }}>Juil</th>
                                          <th style={{ textAlign: 'center' }}>Août</th>
                                          <th style={{ textAlign: 'center' }}>Sept</th>
                                          <th style={{ textAlign: 'center' }}>Oct</th>
                                          <th style={{ textAlign: 'center' }}>Nov</th>
                                          <th style={{ textAlign: 'center' }}>Dec</th>

                                          <th style={{ textAlign: 'center' }}>TOTAL</th>
                                        </tr>
                                      </thead>

                                      <tbody>

                                        {

                                          eachSiteData[site.site_id]?.map((val, index2) => {

                                            // const Janvier = (eachSiteMontsData[site.site_id][val.titre]["01"] || 0);
                                            // const Fevrier = (eachSiteMontsData[site.site_id][val.titre]["02"] || 0);
                                            // const Mars = (eachSiteMontsData[site.site_id][val.titre]["03"] || 0);
                                            // const Avril = (eachSiteMontsData[site.site_id][val.titre]["04"] || 0);
                                            // const Mai = (eachSiteMontsData[site.site_id][val.titre]["05"] || 0);
                                            // const Juin = (eachSiteMontsData[site.site_id][val.titre]["06"] || 0);
                                            // const Juillet = (eachSiteMontsData[site.site_id][val.titre]["07"] || 0);
                                            // const Aout = (eachSiteMontsData[site.site_id][val.titre]["08"] || 0);
                                            // const Septembre = (eachSiteMontsData[site.site_id][val.titre]["09"] || 0);
                                            // const Octobre = (eachSiteMontsData[site.site_id][val.titre]["10"] || 0);
                                            // const Novembre = (eachSiteMontsData[site.site_id][val.titre]["11"] || 0);
                                            // const Decembre = (eachSiteMontsData[site.site_id][val.titre]["12"] || 0);


                                            const Janvier = (eachSiteMontsData[site.site_id][val.titre]["01"]?.nbrExamen || 0);
                                            const Fevrier = (eachSiteMontsData[site.site_id][val.titre]["02"]?.nbrExamen || 0);
                                            const Mars = (eachSiteMontsData[site.site_id][val.titre]["03"]?.nbrExamen || 0);
                                            const Avril = (eachSiteMontsData[site.site_id][val.titre]["04"]?.nbrExamen || 0);
                                            const Mai = (eachSiteMontsData[site.site_id][val.titre]["05"]?.nbrExamen || 0);
                                            const Juin = (eachSiteMontsData[site.site_id][val.titre]["06"]?.nbrExamen || 0);
                                            const Juillet = (eachSiteMontsData[site.site_id][val.titre]["07"]?.nbrExamen || 0);
                                            const Aout = (eachSiteMontsData[site.site_id][val.titre]["08"]?.nbrExamen || 0);
                                            const Septembre = (eachSiteMontsData[site.site_id][val.titre]["09"]?.nbrExamen || 0);
                                            const Octobre = (eachSiteMontsData[site.site_id][val.titre]["10"]?.nbrExamen || 0);
                                            const Novembre = (eachSiteMontsData[site.site_id][val.titre]["11"]?.nbrExamen || 0);
                                            const Decembre = (eachSiteMontsData[site.site_id][val.titre]["12"]?.nbrExamen || 0);


                                            const rowTotal = (parseInt(Janvier) + parseInt(Fevrier) + parseInt(Mars) + parseInt(Avril) + parseInt(Mai) + parseInt(Juin) + parseInt(Juillet) + parseInt(Aout) + parseInt(Septembre) + parseInt(Octobre) + parseInt(Novembre) + parseInt(Decembre));
                                            Janvier_total = (nbrExamen_total_foreach_months[site.site_id]["01"] || 0);
                                            Fevrier_total = (nbrExamen_total_foreach_months[site.site_id]["02"] || 0);
                                            Mars_total = (nbrExamen_total_foreach_months[site.site_id]["03"] || 0);
                                            Avril_total = (nbrExamen_total_foreach_months[site.site_id]["04"] || 0);
                                            Mai_total = (nbrExamen_total_foreach_months[site.site_id]["05"] || 0);
                                            Juin_total = (nbrExamen_total_foreach_months[site.site_id]["06"] || 0);
                                            Juillet_total = (nbrExamen_total_foreach_months[site.site_id]["07"] || 0);
                                            Aout_total = (nbrExamen_total_foreach_months[site.site_id]["08"] || 0);
                                            septembre_total = (nbrExamen_total_foreach_months[site.site_id]["09"] || 0);
                                            Octobre_total = (nbrExamen_total_foreach_months[site.site_id]["10"] || 0);
                                            Novembre_total = (nbrExamen_total_foreach_months[site.site_id]["11"] || 0);
                                            Decembre_total = (nbrExamen_total_foreach_months[site.site_id]["12"] || 0);

                                            totalJAnvier[site.site_id] = (totalJAnvier[site.site_id] || 0) + Janvier;
                                            totalFevrier[site.site_id] = (totalFevrier[site.site_id] || 0) + Fevrier;
                                            totalMars[site.site_id] = (totalMars[site.site_id] || 0) + Mars;
                                            totalAvril[site.site_id] = (totalAvril[site.site_id] || 0) + Avril;
                                            totalMai[site.site_id] = (totalMai[site.site_id] || 0) + Mai;
                                            totalJuin[site.site_id] = (totalJuin[site.site_id] || 0) + Juin;
                                            totalJuillet[site.site_id] = (totalJuillet[site.site_id] || 0) + Juillet;
                                            totalAout[site.site_id] = (totalAout[site.site_id] || 0) + Aout;
                                            totalSeptembre[site.site_id] = (totalSeptembre[site.site_id] || 0) + Septembre;
                                            totalOctobre[site.site_id] = (totalOctobre[site.site_id] || 0) + Octobre;
                                            totalNovembre[site.site_id] = (totalNovembre[site.site_id] || 0) + Novembre;
                                            totalDecembre[site.site_id] = (totalDecembre[site.site_id] || 0) + Decembre;
                                            totaleachRowGet = (totaleachRow[site.site_id] != null ? totaleachRow[site.site_id] : 0);

                                            return (
                                              <tr style={{ textAlign: 'center' }} key={index2}>

                                                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{val.titre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Janvier}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Fevrier}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mars}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Avril}
                                                </td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mai}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juin}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juillet}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Aout}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Septembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Octobre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Novembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Decembre}</td>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{rowTotal}</td>
                                              </tr>

                                            )
                                          })}

                                      </tbody>
                                      <tfoot>
                                        <tr style={{ backgroundColor: 'grey', color: 'white', }}>
                                          <td>

                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {/* {totalJAnvier[site.site_id] || 0} */}
                                            {Janvier_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {/* {totalFevrier[site.site_id] || 0} */}
                                            {Fevrier_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Mars_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Avril_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Mai_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Juin_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Juillet_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Aout_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {/* {(totalSeptembre[site.site_id] || 0)} */}
                                            {septembre_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Octobre_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Novembre_total}
                                          </td>
                                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {Decembre_total}
                                          </td>

                                          <td style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}>{totaleachRowGet}</td>


                                        </tr>
                                      </tfoot>


                                    </table>

                                  </AccordionTab>

                                </Accordion>


                              ))
                            }
                          </AccordionTab>
                        </Accordion>
                        <Panel header="Recherche avancée">
                          <div className='row' style={{ marginLeft: 10 }}>
                            <div className='col col-md-3'>
                              <select className='form-control' style={{ fontWeight: 'bold', fontSize: 12 }}
                                value={societe} onChange={(e) => setSociete(e.target.value)}>
                                <option value={""}>veuillez sélectionner le site</option>
                                {sites.map((si, index) => (
                                  <option style={{ fontWeight: 'bold', fontSize: 12 }} key={index} value={si.societe}>{si.societe}</option>
                                ))}
                              </select>
                            </div>
                            <div className='col col-md-2'>
                              <select className='form-control' style={{ fontSize: 12, fontWeight: 'bold' }}
                                value={modalite} onChange={(e) => setModalite(e.target.value)}
                              >
                                <option value={""}>Modalité</option>
                                {modaliteData.map((val) => (
                                  <option style={{ fontWeight: 'bold', fontSize: 12 }} value={val.code}>{val.code}</option>

                                ))}

                              </select>
                            </div>

                            <div className='col col-md-7'>
                              <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 10 }}>
                                <label style={{ marginTop: 10, fontSize: 12 }}>Age:</label>
                                <FloatLabel>
                                  <InputText id="Age" value={age} onChange={handleInputAge} style={{ width: 50, fontSize: 12, marginLeft: 10 }} />
                                  <label for="Age" style={{ fontSize: 14, marginLeft: 10 }}>De</label>
                                </FloatLabel>
                                <FloatLabel>
                                  <InputText id="Age" value={ageF} onChange={handleInputAgeF} style={{ width: 50, fontSize: 12, marginLeft: 10 }} />
                                  <label for="Age" style={{ fontSize: 14, marginLeft: 10 }}>A</label>
                                </FloatLabel>
                                <select className='form-control' style={{ width: 80, marginLeft: 10 }}
                                  value={sexe} onChange={(e) => setSexe(e.target.value)}
                                >
                                  <option value={""}>Sexe</option>
                                  <option value={'M'}>M</option>
                                  <option value={'F'}>F</option>
                                </select>
                                <FloatLabel>
                                  <InputText id="word" value={motcle} onChange={(e) => setMotcle(e.target.value)} style={{ width: "270px", fontSize: 12, marginLeft: 10 }} />
                                  <label for="word" style={{ fontSize: 14, marginLeft: 10 }}>Mot clé</label>
                                </FloatLabel>
                                <button type='button' style={{ marginLeft: 10 }} className={boolBtn == true ? 'btn btn-info' : 'btn btn-danger'}
                                  onClick={boolBtn == true ? () => rechercher_advanced(membreValue, periode) : () => cancelReseach()}
                                >
                                  <i className={boolBtn == true ? 'fa fa-search' : 'fa fa-remove'}></i>
                                </button>
                              </div>
                            </div>

                          </div>
                        </Panel>

                        <Accordion activeIndex={0} toggle={true}>
                          <AccordionTab header={(headerMainSecond(examen_total))}>
                            <Accordion>
                              {
                                paginatedData?.map((v, index) => (
                                  <AccordionTab header={headerPatient(v.nom_patient, v.titre, v.societe, v.chemin_fichier, v.chemin_fichier_2, v.chemin_bulletin, v.created_at, (v.age + "  " + ((v.libelle_age !== null && v.libelle_age !== "") ? v.libelle_age : "")), v.sexe)}

                                  >
                                    <table className='table'>

                                      <tr>
                                        <h3 className='text-center' style={{ marginTop: "1%", color: 'black', textDecoration: "underline" }}>{v.categorie_fichier}</h3>
                                      </tr>
                                      <tr>
                                        <div style={{ display: 'flex', marginTop: "10px", color: 'green', marginLeft: '70%' }}>

                                          {v.chemin_fichier !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={v.chemin_fichier}
                                            to={'/statistiques'}
                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + v.chemin_fichier)}
                                          >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </p>}

                                          {v.chemin_fichier_2 !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}>&nbsp;<Link
                                            title={v.chemin_fichier_2}
                                            to={'/statistiques'}
                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + v.chemin_fichier_2)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></p>}


                                          {v.chemin_bulletin !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={v.chemin_bulletin} style={{ position: 'absolute' }} to={'/statistiques'} onClick={(e) => handleOpen(e, URL_FOLDER_API + v.chemin_bulletin)}>Bulletin</Link></p>}
                                        </div>
                                      </tr>
                                    </table>

                                    <div style={{ width: "100%", height: "auto", overflow: "hidden" }}>
                                      <JoditEditor
                                        config={config}
                                        ref={editor}
                                        value={v.observation}
                                        tabIndex={1}
                                      />
                                    </div>

                                    {/* <table style={{ width: "100%" }}>
                                      <tr style={{ width: "100%" }}>
                                        <JoditEditor
                                          config={config}
                                          ref={editor}
                                          value={v.observation}
                                          tabIndex={1}
                                        />
                                      </tr>
                                    </table> */}

                                    <table style={{ marginTop: 10, width: "100%" }}>
                                      <tr>
                                        <th>
                                          {v.img_obs_1 && <img src={URL_MAIN + v.img_obs_1} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_1 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.1</h6>}
                                        </th>
                                        <th>
                                          {v.img_obs_2 && <img src={URL_MAIN + v.img_obs_2} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_2 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.2</h6>}
                                        </th>
                                        <th>

                                          {v.img_obs_3 && <img src={URL_MAIN + v.img_obs_3} style={{ width: '150px', height: '150px', float: 'left' }} />}
                                          {v.img_obs_3 && <h6 style={{ marginTop: "75px", marginLeft: 13 }}>Fig.3</h6>}
                                        </th>
                                      </tr>
                                      <tr>

                                      </tr>
                                    </table>

                                  </AccordionTab>
                                ))
                              }

                            </Accordion>
                            <Paginator
                              first={startIndex}
                              rows={rowsPerPage}
                              totalRecords={dataExamenStatistique.length}
                              onPageChange={onPageChange}
                            />
                          </AccordionTab>

                        </Accordion>

                        <Card title="FACTURATION">
                          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                            <label style={{ marginTop: 10, fontWeight: 'bold', fontSize: 12 }}>Site * :</label>
                            <select className='form-control'
                              style={{ width: '57%', fontWeight: 600, color: 'black', marginLeft: 50 }}
                              onChange={(e) => Facturation(e)}
                            >
                              <option value="">Veuillez sélectionner le site </option>
                              {sitesOri.map((si, index) => (
                                <option key={index} value={si.id}>{si.societe}</option>
                              ))}
                            </select>
                          </div>
                          <Divider />
                          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>

                            <label style={{ marginTop: 10, fontWeight: 'bold', fontSize: 12 }}>Date début * :</label>
                            <input type='date'
                              className='form-control'
                              value={datedebut}
                              onChange={(e) => setDateDebut(e.target.value)}
                              style={{ width: '20%', marginLeft: 10 }}
                            />
                            <label style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold', fontSize: 12 }}>Date fin * :</label>
                            <input type='date'
                              className='form-control'
                              value={datefin}
                              onChange={(e) => setDateFin(e.target.value)}
                              placeholder='Date fin' showIcon style={{ marginLeft: 10, width: '20%' }}

                            />
                            <button
                              type='button'
                              className={boolBtnDate == true ? 'btn btn-primary' : 'btn btn-danger'}
                              onClick={boolBtnDate == true ? () => rechercherByIntervalDateFacture() : () => cancelRecherche()}
                              style={{ marginLeft: 10 }}>
                              <i className={boolBtnDate == true ? 'fa fa-search' : 'fa fa-remove'}></i>
                            </button>
                            <button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => generateToPdf()}
                              style={{ marginLeft: 10 }}>
                              <FaRegFilePdf />
                            </button>
                          </div>
                          <Divider />

                          {loadingFacturationSection && periode && site_id ? <h5 className='text-center' style={{ color: "black", fontWeight: 'bold' }}>Chargement de données de facturation en cours...</h5> : ""}
                          {loadingSearchFacturation && <h5 className='text-center' style={{ color: "black", fontWeight: 'bold' }}>Cherche de données de facturation en cours...</h5>}
                          {!loadingFacturationSection && statistiqueAllDataGroupMembre?.map((d, index) => (
                            <div key={index}>
                              <table
                                id={`table_facture_${d.membre_id}`}
                                className="table table-bordered table-striped table-condensed"
                                style={{ marginTop: 10 }}
                              >
                                <thead>
                                  <tr>
                                    <th colSpan={6} style={{ fontSize: 15, color: 'red' }}>
                                      {`${d.nom} ${d.prenom} `}
                                    </th>
                                  </tr>
                                  <tr style={{ backgroundColor: 'grey', color: 'white', }}>
                                    <th>EXAMENS</th>
                                    <th style={{ textAlign: 'center' }}>NBR</th>
                                    <th style={{ textAlign: 'center' }}>P.U</th>
                                    <th style={{ textAlign: 'center' }}>P.T</th>
                                    <th style={{ textAlign: 'center' }}>%</th>
                                    <th style={{ textAlign: 'center' }}>NET A PAYER</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {statistiqueDataFacturation[d.membre_id]?.map((f, idx) => {
                                    const unitPrice = data_FacturationByMembre[f.membre_id][f.titre] || 0;

                                    const totalPrice = parseInt(unitPrice * f.gain);
                                    const netToPay = parseInt((totalPrice * f.pourcentage) / 100);

                                    totalFact += netToPay;
                                    total_prix_uni_value += totalPrice;
                                    total_prix_uni_values[d.membre_id] =
                                      (total_prix_uni_values[d.membre_id] || 0) + totalPrice;
                                    total_net_a_payer_values[d.membre_id] =
                                      (total_net_a_payer_values[d.membre_id] || 0) + netToPay;
                                    total_general_hors_taxe_values += netToPay;
                                    number_total_of_exman[d.membre_id] =
                                      (number_total_of_exman[d.membre_id] || 0) + unitPrice;
                                    // total_amount_of_pu[d.membre_id] =
                                    //   parseFloat((total_amount_of_pu[d.membre_id] || 0)) + f.gain;
                                    const gain = parseFloat(f.gain) || 0; // Si f.gain est NaN ou invalide, il sera remplacé par 0.
                                    total_amount_of_pu[d.membre_id] = parseFloat(total_amount_of_pu[d.membre_id] || 0) + gain;
                                    number_total_of_exmanAll += unitPrice;
                                    total_ca += totalPrice;

                                    return (
                                      <tr key={idx}>
                                        <td style={{ fontWeight: 'bold', color: 'black', width: "47%" }}>{f.titre}</td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', width: "4%" }}>
                                          {formatNumberWithSpaces(unitPrice)}
                                        </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', width: "15%" }}>
                                          {formatNumberWithSpaces(f.gain)}
                                        </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', width: "15%" }}>
                                          {formatNumberWithSpaces(totalPrice)}
                                        </td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', width: "4%" }}>
                                          {f.pourcentage}
                                        </td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', width: "15%" }}>
                                          {formatNumberWithSpaces(netToPay)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr style={{ backgroundColor: 'grey', color: 'white', }}>
                                    <td style={{ fontWeight: 'bold', color: 'white' }}>SOUS-TOTAL</td>
                                    <td style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                                      {number_total_of_exman[d.membre_id] !== null
                                        ? number_total_of_exman[d.membre_id]
                                        : 0}
                                    </td>
                                    <td style={{ fontWeight: 'bold', color: 'white', textAlign: 'right' }}>
                                      {formatNumberWithSpaces(total_amount_of_pu[d.membre_id] || 0)}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {formatNumberWithSpaces(parseFloat(total_prix_uni_values[d.membre_id] || 0))}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {d.pourcentage}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {formatNumberWithSpaces(parseFloat(total_net_a_payer_values[d.membre_id] || 0))}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          ))}

                          <table id='table_facture2' className='table table-bordered table-striped table-condensed'>
                            <thead>

                            </thead>
                            <tfoot>
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>NOMBRE D'EXAMENS</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{number_total_of_exmanAll}</td>
                              </tr>
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>C.A</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(total_ca)}</td>
                              </tr>
                              {/* <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>Total GENERAL HT</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(total_general_hors_taxe_values)}</td>
                              </tr> */}
                              {/* <tr>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>TVA(18%)</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt(total_general_hors_taxe_values * tva))}</td>
                              </tr> */}
                              {/* <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>CA(5%)</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces((parseInt(total_general_hors_taxe_values * tva) * ca))}</td>
                              </tr> */}
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>NET A PAYER</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt((total_general_hors_taxe_values)))}</td>
                              </tr>
                            </tfoot>
                          </table>
                          <table id='table_facture3' >
                            <tr>

                              <th style={{ textAlign: 'right', color: 'white' }}>La direction </th>


                            </tr>
                          </table>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>}
          </div>
          <br />
        </section>
      </section>}
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default Statistiques;