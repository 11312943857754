import React from 'react'
function LoadingSpinner() {
    return (
        <div style={{ 
            position: "absolute", 
            height: "100%", 
            width: "100%", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            alignContent: "center", 
            flexDirection: "column" 
        }}>
             <div class="spinner"></div>
            
        </div>
    )
}

export default LoadingSpinner
