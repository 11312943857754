import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { Card } from 'primereact/card';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import { Link, useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge'
import { ThemeContext } from '../../context/ContextHeader';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function Resultats() {

    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const site_id = sessionStorage.getItem("site_id");
    const [isChecked, setIsChecked] = useState([]);

    //const [dossiers, setDossiers] = useState([]);
    //const [dossiersEn, setDossiersEn] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [sites, setSites] = useState([]);
    const [statusResultat, setStatusResultat] = useState(false);
    const useRefInput = useRef(null);
    const [loading,setLoading]= useState(true);


    const {
        text, setText,
        datedebut, dossiersEn,
        setDossiersEn, dossiers,
        setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis,
        medecinID, setMedecinID,
        DossiersRecu, setDossiersRecu
    } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = DossiersRecu.slice(firstIndex, lastIndex);
    const npage = Math.ceil(DossiersRecu.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [boolBtn, setBoolBtn] = useState(false);
    const [checked, setChecked] = useState(false);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const ArchiverDossierRecuTraitement = async () => {
        try {
            for (const val of isChecked) {

                const response = await axios.post(URL_AXIOS + `ArchiverDossierRecu/${val}`);
                if (response.data.status == 200) {
                    liste_dossiers_by_site();
                    alert("fklgfdljk")
                }

            }
        } catch (error) {

        }
    }
    const ArchiverDossierRecu = async () => {
        try {
            Swal.fire({
                title: "Archivages",
                text: "Voulez-vous archiver ce dossiers !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    ArchiverDossierRecuTraitement();
                    // liste_dossiers_by_site();

                }
            });
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const handleCheckedInput = (e) => {
        const value = e.target.value; // Get the value of the checkbox
        const checked = e.target.checked;
        let updatedArray;
        if (checked) {
            setChecked(true)
            updatedArray = [...isChecked, value];
        } else {
            setChecked(false)
            updatedArray = isChecked.filter((item) => item !== value);
        }
        setIsChecked(updatedArray);

        if (updatedArray.length === 0) {
            setBoolBtn(false);

        } else {
            setBoolBtn(true);

        }

    };

    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';
        link.href = value;
        link.click();
    };
    const handleApercu = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        // link.download = 'Example-PDF-File';
        link.href = `/apercu/${value}`;
        link.target = "_blank";
        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };
    const liste_dossiers_by_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_resultatAdmin");
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);
                setLoading(false)
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>

                <Checkbox value={data.id} name={`check_${data.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(data.id)} />
                {/* <i title='Impression' className='fa fa-print' style={{ fontSize: "15px", marginLeft: 10, marginTop: 5, cursor: 'pointer', color: 'green' }} onClick={(e) => handleApercu(e, data.id)}></i> */}
                {/* <button type='button' className='btn btn-success'
                  
                > */}
                    <i   onClick={(e) => handleApercu(e, data.id)} style={{marginLeft: 10, marginTop: 5, cursor: 'pointer', color: 'green' }}  className='fa fa-print'></i>
                {/* </button> */}
                <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, (URL_FOLDER_API + data.chemin_fichier), (URL_FOLDER_API + data.chemin_fichier_2))}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14,
                        marginTop: 5
                    }}
                    title='Télécharger examen'
                >
                </i>
            </div>
        )
    }

    useEffect(() => {
        setDisableAvis(true);
        liste_dossiers_by_site();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
           {loading ? <Loading /> :  <section id="main-content">
                <section class="wrapper">

                    <div className='container-fluid mt'>

                        <div className='row'>
                            <div className='col col-md-8'>

                            </div>
                            <div className='col col-md-4'>
                                <button type='button'
                                    className='btn btn-warning'
                                    style={{ fontWeight: 'bold', float: 'right' }}
                                    onClick={() => ArchiverDossierRecu()}
                                    disabled={boolBtn === false ? true : false}
                                >Archiver les dossiers sélectionnés
                                    &nbsp; <i className='fa fa-upload'></i>
                                </button>
                            </div>
                        </div>
                        <div class="row " style={{ padding: 10 }}>

                            <DataTable value={DossiersRecu} emptyMessage="Aucun dossier disponible!" paginator rowClassName={(rowdata) => parseInt(rowdata.gain) === 0 ? 'bgColorRow' : ''}  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                                {/* <Column header={() => { return <div style={{ marginLeft: 20, padding: '0 10px' }}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px", padding: '0 10px' }} field='id' style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column> */}
                                <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px', padding: '0 10px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column field='nom_pat' header="PATIENTS" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field={'categorie_fichier'} header="TYPE EXAMEN" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field={(data) => { return data.nom_medecin + ' ' + data.prenom_medecin }} header="RADIOLOGUE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field='created_at' header="ENVOYE LE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column field='updated_at' header="RECU LE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column  body={(data)=>{return data.updated_at==data.updated_at_inter ? "":data.updated_at_inter}} header="MODIFIE LE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                <Column header="ACTIONS" body={ActionBody} style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            </DataTable>
                        </div>
                    </div>




                    <br /><br /><br /><br />
                </section>
            </section>}
            <Footer />
        </div>
    );
}

export default Resultats;