import React, { useContext } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useEffect } from 'react';
import { Badge } from 'primereact/badge';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { RadioButton } from "primereact/radiobutton";
import Footer from '../Footer';
import { Dialog } from 'primereact/dialog';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';

function Users() {

    const navigate = useNavigate();
    const { user_data, setUser_data } = useContext(ThemeContext);
    //const [user_data, setUser_data] = useState([]);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = user_data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(user_data.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [id, setId] = useState(0);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    const [sites, setSites] = useState([]);
    const [societe, setSociete] = useState("");
    const [email, setEmail] = useState("");
    const [nom, setNom] = useState("");
    const [confirme, setConfirme] = useState("");
    const [adresselocal, setAdresselocal] = useState("");
    const [statut, setStatut] = useState(1);
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [ingredient, setIngredient] = useState('');
    const [message, setMessage] = useState('');
    const [getvalue, setGetvalue] = useState('');
    const [pays, setPays] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [userId, setUserID] = useState("");
    const [message_mail, setMessage_mail] = useState("");
    const [message_nom, setMessage_nom] = useState("");
    const [message_societe, setMessage_societe] = useState("");
    const [message_role, setMessage_role] = useState("");
    const [message_password, setMessage_password] = useState("");
    const [message_confirme, setMessage_confirme] = useState("");
    const [loading, setLoading] = useState(true);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const liste_site_users = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                setUser_data(response.data.users_sites);
                setLoading(false)
                //setUser(response.data.users_sites.length)
            }
        } catch (Error) {

        }

    }
    const handleQueryText = (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);
            setCurrentPage(1);

            if (query_name.length > 0) {
                const search_data = user_data.filter(item =>
                    item.name.toLowerCase().includes(query.toLowerCase())
                );

                setUser_data(search_data);
            } else {
                liste_site_users();

            }
        } catch (error) {

        }

    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(user_data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'utilisateurs');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const updateConfirm = () => {
        setVisible(false);
        Swal.fire({
            title: "Question",
            text: "Voulez-vous mettre à jour cet utilisateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then((result) => {
            if (result.isConfirmed) {

                update_user();
            }
        });
    }
    const confirm2 = (id) => {

        Swal.fire({
            title: "Question!",
            text: "Voulez-vous supprimer cet utilisateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {

                setId(id);
                delete_user(id);
                liste_site_users();
            }
        });

    };
    const delete_user = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_user/${id}`);
            if (response.data.status == 200) {
                Swal.fire({
                    title: "Réussi!",
                    text: "L'utilisateur a été supprimé avec succès.",
                    icon: "success"
                });
                liste_site_users();

            }
        } catch (Error) {

        }

    }
    const liste_users_by_id = async (id) => {
        try {
            await axios.get(URL_AXIOS + `get_users_by_id/${id}`)
                .then(response => {

                    setSociete(response.data.users.societe);
                    setNom(response.data.users.name);
                    setEmail(response.data.users.email);
                    setGetvalue(response.data.users.site_id);
                    setRole(response.data.users.role);
                    setPassword(response.data.users.password)
                    setConfirme(response.data.users.password)
                    setUserID(response.data.users.id)

                    response.data.users.statut == 1 ? setIngredient("oui") : setIngredient("non")

                    setVisible(true)
                });
        } catch (Error) {

        }

    }


    const handleInputSociete = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSociete(value)
            setMessage_societe("");
        } else {
            setSociete("")
        }
    }
    const handleInputPassword = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPassword(value)
            setMessage_password("");
        } else {
            setPassword("")
        }
    }
    const handleInputNom = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNom(value)
            setMessage_nom("");
        } else {
            setNom("")
        }
    }

    const handleInputRole = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setRole(value)
            setMessage_role("");
        } else {
            setRole("")
        }
    }

    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setEmail(value)
            setMessage_mail("");
        } else {
            setEmail("")
        }
    }

    const validationFields = () => {

        if (societe == "") {

            setMessage_societe("Veuillez sélectionner la société");

        }

        if (nom == "") {

            setMessage_nom("Veuillez saisir le nom");

        }

        if (role == "") {

            setMessage_role("Veuillez sélectionner le rôle");

        }

        if (email == "") {

            setMessage_mail("Veuillez saisir le mail");

        }

        if (password == "") {

            setMessage_password("Veuillez saisir le password");

        }

        if (confirme == "") {

            setMessage_confirme("Veuillez confimer le mot de passe");

        }

    }
    const EmailValidation = (email) => {
        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {

            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };
    const removeIdenticalCharacters = (str) => {
        return str.replace(/(.)\1+/g, '$1');
    }

    const ClearFields = () => {
        setEmail("")
        setNom("")
        setSociete("")
        setPassword("")
        setConfirme("")
        setRole("")
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const handleRoleChange = (e) => {
        const name_get = e.target.value;
        setRole(name_get);
    };
    const handleSocieteChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setSociete(name_get)
            setMessage_societe("");
        } else {
            setSociete("")
        }

        // Assuming 'sites' is an array of objects with a property called 'id'
        const filtered = sites.filter((item) => item.id == name_get);


        // Make sure 'filtered' is not empty before accessing its elements
        if (filtered.length > 0) {
            // Assuming 'societe' is a property of the first item in the 'filtered' array
            const societeValue = filtered[0].societe;

            setSociete(societeValue);
            setGetvalue(name_get);
            //const filtered_two = sites.filter((item) => item.societe !== societeValue);
            //console.log("ARRAY "+JSON.stringify(filtered_two))
            //setSites(filtered_two)

        } else {
            // Handle the case when no matching item is found in 'sites'
            setSociete(""); // Set to an appropriate default value
            console.log("scf societe: No matching item found.");
        }
    };

    const update_user = async () => {
        try {
            const data_user = {
                name: nom.toUpperCase(),
                email: email,
                password: password,
                role: role.toUpperCase(),
                site_id: getvalue,
                statut: ingredient == "oui" ? 1 : 0
            }
            validationFields();
            if (EmailValidation(email)) {
                if (societe != "" || email != "" || nom != "" || role != "") {
                    if (ingredient != "") {

                        const response = await axios.post(URL_AXIOS + `update_user_api/${userId}`, data_user);
                        if (response.data.status == 200) {
                            Swal.fire({
                                title: "Réussi!",
                                text: "L'utilisateur a été modifié avec succès.",
                                icon: "success"
                            });
                            setVisible(false);
                            liste_site_users();


                        } else {
                            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Error !' });

                        }
                    } else {
                        setMessage("Les deux mot de passe doivent être pareil")
                    }



                } else {

                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Veuillez cocher le type de validation', life: 3000 });
                }

            }

        } catch (error) {
            //show();
        }



    }
    const handlePasswordConfirme = (event) => {
        const value = event.target.value;
        setConfirme(value);
        if (value == password) {

            setMessage_confirme("");

        } else {
            setMessage_confirme("Les deux mot de passe doivent être pareil")
        }
    }
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => liste_users_by_id(data.id)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>

                <button type='button' style={{ marginLeft: 10 }} onClick={() => confirm2(data.id)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    // const conditionStatutUser = (data) => {
    //     const cond=((data.statut=="0" && (data.role=="A" || data.role=="C")) ? "Désactivé" :(data.statut=="1" && (data.role=="A" || data.role=="C")) ? "Activé" :(data.statut=="0" && data.statutnewmembre=="0" && data.statutmembre=="0" && data.role=="M") ? "Non confirmé" :(data.statut=="0" && data.statutnewmembre=="1" && data.statutmembre=="0" && data.role=="M") ? "Désactivé / NC" :(data.statut=="1" && data.statutnewmembre=="1" && data.statutmembre=="1" && data.role=="M") ? "Activé":(data.statut=="1" && data.statutnewmembre=="1" && data.statutmembre=="0" && data.role=="M") ? "Non confirmé" :(data.statut=="0" && data.statutnewmembre=="1" && data.statutmembre=="1" && data.role=="M") ? "Désactivé":"")
    //     return (
    //         <div>
    //             {cond}
    //         </div>
    //     )
    // }
    const conditionStatutUser = (data) => {
        let cond = "";

        if (data.statut === "0" && (data.role === "A" || data.role === "C" || data.role === "S")) {
            cond = "Désactivé";
        } else if (data.statut === "1" && (data.role === "A" || data.role === "C" || data.role === "S")) {
            cond = "Activé";
        } else if (data.statut === "0" && data.statutnewmembre === "0" && data.statutmembre === "0" && data.role === "M") {
            cond = "Non confirmé";
        } else if (data.statut === "0" && data.statutnewmembre === "1" && data.statutmembre === "0" && data.role === "M") {
            cond = "Désactivé / NC";
        } else if (data.statut === "1" && data.statutnewmembre === "1" && data.statutmembre === "1" && data.role === "M") {
            cond = "Activé";
        } else if (data.statut === "1" && data.statutnewmembre === "1" && data.statutmembre === "0" && data.role === "M") {
            cond = "Non confirmé";
        } else if (data.statut === "0" && data.statutnewmembre === "1" && data.statutmembre === "1" && data.role === "M") {
            cond = "Désactivé";
        }

        return (
            <div>
                {cond}
            </div>
        );
    };

    const conditionStatutUserColorRows = (data) => {
        let cond = "";
         
        if (data.statut === "0" && (data.role === "A" || data.role === "C" || data.role === "S")) {
            // cond = "Désactivé";
            return "bgColorRowAbonnExp";
        } else if (data.statut === "1" && (data.role === "A" || data.role === "C" || data.role === "S")) {
            // cond = "Activé";
            return "bgColorRowAgenda";
        } else if (data.statut === "0" && data.statutnewmembre === "0" && data.statutmembre === "0" && data.role === "M") {
            // cond = "Non confirmé";
            return "bgColorRowAbonnProgramm";
        } else if (data.statut === "0" && data.statutnewmembre === "1" && data.statutmembre === "0" && data.role === "M") {
            // cond = "Désactivé / NC";
            return "bgColorRowAbonnExp";
        } else if (data.statut === "1" && data.statutnewmembre === "1" && data.statutmembre === "1" && data.role === "M") {
            // cond = "Activé";
            return "bgColorRowAgenda";
        } else if (data.statut === "1" && data.statutnewmembre === "1" && data.statutmembre === "0" && data.role === "M") {
            return "bgColorRowAbonnProgramm";
        } else if (data.statut === "0" && data.statutnewmembre === "1" && data.statutmembre === "1" && data.role === "M") {
            return "bgColorRowAbonnExp";
        }
    };
    useEffect(() => {
        liste_site_users();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Dialog header="Utilisateurs" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <form role="form" class="form-horizontal style-form">
                    <div class="form-group ">

                        <div class="col-lg-6">
                            <input type="text" value={nom} onChange={handleInputNom} placeholder="Nom(s) complets *" class="form-control"
                                style={{ fontWeight: 'bolder' }} />
                            {message_nom !== "" ? <label style={{ color: 'red' }}>{message_nom}</label> : ""}
                        </div>
                        <div class="col-lg-6">

                            <select className='form-control' value={societe} onChange={handleSocieteChange}
                                style={{ fontWeight: 'bolder' }}>
                                <option value={id !== "" ? societe : ""}>{id !== "" ? societe : "Sélectionner une société *"}</option>

                                {sites.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.societe}

                                    </option>
                                ))}
                            </select>
                            {message_societe !== "" ? <label style={{ color: 'red' }}>{message_societe}</label> : ""}

                        </div>
                    </div>
                    <div class="form-group ">

                        <div class="col-lg-6">
                            <select className='form-control'
                                style={{ fontWeight: 'bolder' }}
                                value={role} onChange={handleInputRole}>
                                <option>{id !== "" ? role == "M" ? "Médecin" : role == "A" ? "Administrateur" : role == "C" ? "Agent du centre" : "" : "Rôle *"}</option>
                                {role == "A" ? "" : <option value={'A'}>Administrateur</option>}
                                {role == "M" ? "" : <option value={'M'}>Médecin</option>}
                                {role == "C" ? "" : <option value={'C'}>Agent du centre</option>}
                            </select>
                            {message_role !== "" ? <label style={{ color: 'red' }}>{message_role}</label> : ""}
                        </div>

                        <div class="col-lg-6">
                            <input type="email" value={email} onChange={handleInputEmail} placeholder="Email *" class="form-control" />
                            {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}
                        </div>
                    </div>

                    <div class="form-group">


                        <div class="col-lg-3">
                            <input type="hidden" value={password} onChange={handleInputPassword} placeholder="Mot de passe *" class="form-control" />
                            {message_password !== "" ? <label style={{ color: 'red' }}>{message_password}</label> : ""}

                        </div>
                        <div class="col-lg-3">
                            <input type="hidden" value={confirme} onChange={handlePasswordConfirme} placeholder="Confirmer votre mot de passe *" class="form-control" />
                            {message_confirme !== "" ? <label style={{ color: 'red' }}>{message_confirme}</label> : ""}

                        </div>
                    </div>
                    <div className="form-group ">

                        <div className="flex" style={{ display: "flex", justifyContent: "center" }}>
                            <label className="ml-2" style={{ marginLeft: 5 }}> Status de validation :</label>
                            <RadioButton inputId="ingredient1" name="pizza" style={{ marginLeft: 10 }} value="oui" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'oui'} />
                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 10 }}>Oui</label>
                            <RadioButton inputId="ingredient2" name="pizza" value="non"
                                onChange={(e) => setIngredient(e.value)} checked={ingredient === 'non'}
                                style={{ marginLeft: 10 }} />
                            <label htmlFor="ingredient2" style={{ marginLeft: 10 }} className="ml-2">Non</label>
                        </div>



                    </div>
                    <div class="form-group">
                        <div class="col-lg-10">
                            <button class="btn btn-primary" type="button"
                                onClick={() => updateConfirm()}
                            >Mettre à jour</button>

                        </div>
                    </div>
                </form>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8">
                            <Button className='btn btn-info' label='Nouveau utilisateur'
                                onClick={() => navigate('/add_users')}
                                style={{ display: 'none' }}
                            />
                            <Button type="button" icon="pi pi-file-excel" severity="success" label='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            />
                        </div>

                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={user_data} paginator emptyMessage="Aucun utilisateur disponible!" rows={10} rowClassName={conditionStatutUserColorRows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                            <Column field='name' header="NOMS" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'societe'} header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field='role' header="ROLE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="STATUT" body={conditionStatutUser} style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                        </DataTable>
                        {/* <table class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>NOMS</th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}><center>ROLE</center></th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>
                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.name}</td>
                                            <td>{si.societe}</td>
                                            <td>{si.email}</td>
                                            <td><center>{si.role}</center></td>
                                            <td><center><button type='button' onClick={() => liste_users_by_id(si.id)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>

                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}

            <Footer />
        </section>
    );
}

export default Users;